import { getFormConfig } from "./configs/formConfig";
import { changePwd } from '@/api/password';

export default {
  data: function () {
    const formConfig = getFormConfig(this)

    return {
      formData: {}, //筛选条件
      ...formConfig
    }
  },
  methods: {
    async handleSubmit() {

      let success = await this.$refs.filterForm.validate();
      if (!success) {
        return;
      }

      let { oldpassword, newPassword } = this.$refs.filterForm.formData;

      let { status, message } = await changePwd({
        PASSWORD: oldpassword,
        NEW_PASSWORD: newPassword
      });

      if (status === 200) {
        this.$message.success(message)
      }
      
    },
  }
}